<template>
    <VModalWrapper :title="title" v-on="listeners" v-bind="attributes">
        <ValidationObserver v-slot="{ handleSubmit }">
            <form class="active-form" @submit.prevent="handleSubmit(submit)">
                <ValidationProvider
                    class="active-form__field"
                    name="security-group"
                    rules="required"
                    v-slot="{ errors }"
                >
                    <label class="active-form__label active-form__label_required" for="request-security-group"
                        >Группа исполнителей</label
                    >

                    <VSelect
                        id="request-security-group"
                        name="security-group"
                        label="name"
                        class="active-form__control"
                        placeholder="Выберите группу исполнителей"
                        :class="{
                            error: errors[0],
                        }"
                        :options="securityGroups"
                        @input="resetExecutor"
                        v-model="securityGroup"
                    ></VSelect>

                    <span class="active-form__error">
                        {{ errors[0] }}
                    </span>
                </ValidationProvider>

                <ValidationProvider class="active-form__field" name="executor" v-slot="{ errors }">
                    <label class="active-form__label" for="request-executor">Исполнитель</label>

                    <VSelect
                        id="request-executor"
                        name="executor"
                        label="displayName"
                        class="active-form__control"
                        placeholder="Выберите исполнителя"
                        :class="{
                            error: errors[0],
                        }"
                        :options="securityGroup ? securityGroup.accounts : []"
                        v-model="executor"
                    ></VSelect>

                    <span class="active-form__error">
                        {{ errors[0] }}
                    </span>
                </ValidationProvider>

                <ValidationProvider class="active-form__field" name="comment" v-slot="{ errors }">
                    <label class="active-form__label" for="request-comment">Комментарий</label>

                    <VContenteditable
                        id="request-comment"
                        name="comment"
                        class="active-form__control"
                        :class="{
                            error: errors[0],
                        }"
                        @submit="handleSubmit(submit)"
                        v-model="comment"
                    ></VContenteditable>

                    <span class="active-form__error">
                        {{ errors[0] }}
                    </span>
                </ValidationProvider>

                <div class="active-form__actions">
                    <VButton type="button" class="active-form__action button_green-light" @click="hide">
                        Отмена
                    </VButton>

                    <VButton type="submit" class="active-form__action button_green"> Изменить </VButton>
                </div>
            </form>
        </ValidationObserver>
    </VModalWrapper>
</template>
<script>
// Components
import VSelect from '../components/VSelect/VSelect.vue';
import VButton from '../components/VButton/VButton.vue';
import VModalWrapper from '../components/VModalWrapper/VModalWrapper.vue';
import VContenteditable from '../components/VContenteditable/VContenteditable.vue';

import { ValidationObserver, ValidationProvider } from 'vee-validate';

// Other
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import ModalNames from '../../js/enums/ModalNames';
import ModalMixin from '../../js/vue/mixins/ModalMixin';
import ErrorMessages from '../../js/enums/ErrorMessages';
import { namespace } from 'vuex-class';

const Workspace = namespace('workspace');

@Component({
    components: {
        VSelect,
        VButton,
        VModalWrapper,
        VContenteditable,

        ValidationObserver,
        ValidationProvider,
    },
})
class VRequestChangeExecutorModal extends ModalMixin {
    @Prop({ type: String, default: ModalNames.REQUEST_CHANGE_EXECUTOR })
    name;
    @Prop({ type: String, default: 'Выберите исполнителя обращения' })
    defaultTitle;

    @Workspace.State securityGroups;

    comment = null;
    executor = null;
    securityGroup = null;

    request = null;
    errorMessage = null;

    submit() {
        this.resolve({
            comment: this.comment,
            executorId: this.executor?.id,
            securityGroupId: this.securityGroup.id,
        });

        this.hide();
    }

    resetExecutor() {
        this.executor = null;
    }

    beforeOpen(event) {
        if (!event?.params?.request) {
            this.errorMessage = ErrorMessages.SOMETHING_WENT_WRONG;
        }

        this.request = event.params.request;

        this.executor = this.request.executor;
        this.securityGroup = this.request.securityGroup;
    }

    beforeClose() {
        this.comment = null;
        this.executor = null;
        this.securityGroup = null;

        this.request = null;
        this.errorMessage = null;
    }
}

export default VRequestChangeExecutorModal;
</script>
