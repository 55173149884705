<template>
    <VModalWrapper :title="title" :is-loading="isLoading" v-on="listeners" v-bind="attributes">
        <ValidationObserver v-slot="{ handleSubmit }">
            <form class="active-form" @submit.prevent="handleSubmit(submit)">
                <ValidationProvider class="active-form__field" name="label" rules="required|max:64" v-slot="{ errors }">
                    <label class="active-form__label active-form__label_required" for="security-group-label"
                        >Название</label
                    >

                    <VInput
                        id="security-group-label"
                        name="label"
                        class="active-form__control"
                        :class="{
                            error: errors[0],
                        }"
                        @submit="handleSubmit(submit)"
                        v-model="securityGroup.name"
                    />

                    <span class="active-form__error">
                        {{ errors[0] }}
                    </span>
                </ValidationProvider>

                <ValidationProvider
                    class="active-form__field"
                    name="commonName"
                    rules="required|max:128"
                    v-slot="{ errors }"
                >
                    <label class="active-form__label active-form__label_required" for="security-group-commonName"
                        >Общее имя (Active Directory)</label
                    >

                    <VInput
                        id="security-group-commonName"
                        name="commonName"
                        class="active-form__control"
                        :class="{
                            error: errors[0],
                        }"
                        @submit="handleSubmit(submit)"
                        v-model="securityGroup.commonName"
                    />

                    <span class="active-form__error">
                        {{ errors[0] }}
                    </span>
                </ValidationProvider>

                <ValidationProvider
                    class="active-form__field"
                    name="location"
                    rules="required|max:48"
                    v-slot="{ errors }"
                >
                    <label class="active-form__label active-form__label_required" for="security-group-location"
                        >Часовой пояс</label
                    >

                    <VSelect
                        id="security-group-location"
                        name="location"
                        label="name"
                        class="active-form__control"
                        :class="{
                            error: errors[0],
                        }"
                        :options="timezones"
                        @submit="handleSubmit(submit)"
                        v-model="securityGroup.timezone"
                    />

                    <span class="active-form__error">
                        {{ errors[0] }}
                    </span>
                </ValidationProvider>

                <ValidationProvider class="active-form__field" name="location" v-slot="{ errors }">
                    <label class="active-form__label" for="security-group-location">Учасники</label>

                    <VSelect
                        id="security-group-location"
                        name="location"
                        label="displayName"
                        class="active-form__control"
                        multiple
                        :class="{
                            error: errors[0],
                        }"
                        :options="users"
                        @submit="handleSubmit(submit)"
                        v-model="securityGroup.accounts"
                    />

                    <span class="active-form__error">
                        {{ errors[0] }}
                    </span>
                </ValidationProvider>

                <div class="active-form__actions">
                    <VButton type="button" class="active-form__action button_green-light" @click="hide">
                        Отмена
                    </VButton>

                    <VButton type="submit" class="active-form__action button_green"> Сохранить </VButton>
                </div>
            </form>
        </ValidationObserver>
    </VModalWrapper>
</template>
<script>
// Components
import VInput from '../components/VInput/VInput.vue';
import VButton from '../components/VButton/VButton.vue';
import VSelect from '../components/VSelect/VSelect.vue';
import VModalWrapper from '../components/VModalWrapper/VModalWrapper.vue';
import VContenteditable from '../components/VContenteditable/VContenteditable.vue';

import { ValidationObserver, ValidationProvider } from 'vee-validate';

// Other
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import ModalNames from '../../js/enums/ModalNames';
import ModalMixin from '../../js/vue/mixins/ModalMixin';
import { getAllTimezones, getAllUsers } from '../../js/admin-api';

@Component({
    components: {
        VInput,
        VButton,
        VSelect,
        VModalWrapper,
        VContenteditable,

        ValidationObserver,
        ValidationProvider,
    },
})
class VAdminSecurityGroupCard extends ModalMixin {
    @Prop({ type: String, default: ModalNames.ADMIN_PANEL_SECURITY_GROUP_CARD })
    name;
    @Prop({ type: String, default: 'Карточка группы ответственных' })
    defaultTitle;

    users = [];
    isLoading = true;
    timezones = [];
    securityGroup = null;

    submit() {
        this.resolve(this.securityGroup);

        this.hide();
    }

    async fetchUsers() {
        if (!this.timezones?.length) {
            const response = await getAllUsers(1, '', 100000);
            this.users = response.data.data.map((user) => ({
                id: user.id,
                displayName: user.displayName,
            }));
        }
    }

    async fetchTimezones() {
        if (!this.timezones?.length) {
            const response = await getAllTimezones();
            this.timezones = response.data;
        }
    }

    async beforeOpen(event) {
        this.securityGroup = event?.params?.securityGroup;

        this.isLoading = true;

        try {
            await Promise.all([this.fetchUsers(), this.fetchTimezones()]);
        } finally {
            this.isLoading = false;
        }
    }

    beforeClose() {
        this.securityGroup = null;
    }
}

export default VAdminSecurityGroupCard;
</script>
