<template>
    <main>
        <ContentHeaderDefault
            title="Пользователи"
            button="Создать пользователя"
            @search="onSearch"
            @button="onButtonClick"
        ></ContentHeaderDefault>

        <template v-if="isLoading">
            <div class="admin-page__loader">
                <VSpinner></VSpinner>
            </div>
        </template>

        <template v-else>
            <ContentTableDefault
                :rows="rows"
                :page="page"
                :total="total"
                :options="options"
                @page="onPageChange"
                @edit="onUserClick"
                @click="onUserClick"
            ></ContentTableDefault>
        </template>

        <VAdminUserCard></VAdminUserCard>
    </main>
</template>
<script>
// Components
import VSpinner from '../../components/VProgress/VSpinner.vue';
import PagePaginate from '../../components/PagePaginate/PagePaginate.vue';
import VAdminUserCard from '../../modals/VAdminUserCard.vue';
import ContentTableDefault from './components/ContentTableDefault.vue';
import ContentHeaderDefault from './components/ContentHeaderDefault.vue';

// Other
import Vue from 'vue';
import Component from 'vue-class-component';
import { errorMessage } from '../../../js/error';
import { createUser, updateUser, getAllUsers } from '../../../js/admin-api';
import { Watch } from 'vue-property-decorator';
import ModalNames from '../../../js/enums/ModalNames';
import AccountRoleType from '../../../js/enums/AccountRoleType';

@Component({
    components: {
        VSpinner,
        PagePaginate,
        VAdminUserCard,
        ContentTableDefault,
        ContentHeaderDefault,
    },
})
class AdminPageUsers extends Vue {
    rows = [];
    page = 1;
    total = 1;
    isLoading = true;
    searchString = '';
    options = {
        editable: true,
        deletable: false,
        columns: [
            {
                size: 5,
                label: '№',
                display: (x) => x.id,
            },
            {
                size: 20,
                label: 'Имя',
                display: (x) => x.displayName,
            },
            {
                size: 20,
                label: 'E-mail',
                display: (x) => x.email,
            },
            {
                size: 15,
                label: 'Город',
                display: (x) => x.location,
            },
            {
                size: 15,
                label: 'Рабочий тел.',
                display: (x) => x.telephone,
            },
            {
                size: 15,
                label: 'Создан',
                display: (x) => this.$_toLocalDateHistory(x.createdAt),
            },
        ],
    };

    onSearch(searchString) {
        this.page = 1;
        this.searchString = searchString;
    }

    onPageChange(page) {
        this.page = page;
    }

    async onUserClick(user) {
        try {
            user = await this.$showModal(ModalNames.ADMIN_PANEL_USER_CARD, {
                user: user,
            });

            this.isLoading = true;

            try {
                await this.updateUser(user);
                await this.fetchUsers();
            } catch (error) {
                let message = errorMessage(error);

                if (error?.response?.data?.errors?.email) {
                    message = 'Почтовый адрес уже занят';
                }

                this.$showModal(ModalNames.ERROR, { errorMessage: message });
            } finally {
                this.isLoading = false;
            }
        } catch (error) {
            // The modal was closed.
            return;
        }
    }

    async onButtonClick() {
        try {
            const user = await this.$showModal(ModalNames.ADMIN_PANEL_USER_CARD, {
                user: {
                    name: '',
                    company: '',
                    department: '',
                    email: '',
                    fullName: '',
                    location: '',
                    login: '',
                    mobile: '',
                    photo: '',
                    telephone: '',
                    title: '',
                    roles: [{ role: AccountRoleType.USER }],
                },
            });

            this.isLoading = true;

            try {
                await this.createUser(user);
                await this.fetchUsers();
            } catch (error) {
                let message = errorMessage(error);

                if (error?.response?.data?.errors?.email) {
                    message = 'Почтовый адрес уже занят';
                }

                this.$showModal(ModalNames.ERROR, { errorMessage: message });
            } finally {
                this.isLoading = false;
            }
        } catch (error) {
            // The modal was closed.
            return;
        }
    }

    createUser(user) {
        return createUser(user);
    }

    updateUser(user) {
        return updateUser(user);
    }

    @Watch('page')
    @Watch('searchString')
    async fetchUsers() {
        this.isLoading = true;

        try {
            const response = await getAllUsers(this.page, this.searchString);

            this.rows = response.data.data;
            this.total = response.data.total;
        } catch (error) {
            const message = errorMessage(error);

            this.$showModal(ModalNames.ERROR, { errorMessage: message });
        } finally {
            this.isLoading = false;
        }
    }

    created() {
        this.fetchUsers();
    }
}

export default AdminPageUsers;
</script>
<style lang="scss"></style>
