<template>
    <div class="request-header">
        <h1 class="request-header__title">Обращение {{ request.id }}</h1>

        <div class="request-header__actions">
            <VButtonDropdown
                class="button_green-light request-header__action"
                :actions="additionalButtons"
                v-if="additionalButtons && additionalButtons.length"
            >
                <span>Доп.действия</span>

                <DotsMenuSvg class="button__icon button__icon_right"></DotsMenuSvg>
            </VButtonDropdown>

            <VButton
                class="button_green request-header__action"
                v-if="activeButton"
                @click.prevent="activeButton.action"
            >
                {{ activeButton.name }}
            </VButton>
        </div>
    </div>
</template>
<script>
// Svg
import DotsMenuSvg from '@/images/dots-menu.svg';

// Components
import VButton from '@/views/components/VButton/VButton.vue';
import VButtonDropdown from '@/views/components/VButton/VButtonDropdown.vue';
import AccountRoleType from '@/js/enums/AccountRoleType';

// Other
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import RequestStatusType from '@/js/enums/RequestStatusType';
import { DateTime } from 'luxon';

@Component({
    components: {
        DotsMenuSvg,

        VButton,
        VButtonDropdown,
    },
})
class RequestViewHeader extends Vue {
    @Prop({ type: Object, required: true })
    request;

    actions = {
        accept: {
            name: 'Принять работу',
            action: this.emit('accept'),
            order: 1,
            when: (request, user) => {
                return (
                    request.authorId === user.id &&
                    [RequestStatusType.FINISH, RequestStatusType.DENIED].includes(request.status)
                );
            },
        },
        toWork: {
            name: 'Вернуть в работу',
            action: this.emit('actualize'),
            order: 1,
            when: (request, user) => {
                return (
                    request.authorId === user.id &&
                    [RequestStatusType.FINISH, RequestStatusType.DENIED, RequestStatusType.REWORK, RequestStatusType.CLOSED].includes(
                        request.status,
                    ) &&
                    this.canReturnToWork()
                );
            },
        },
        revoke: {
            name: 'Отозвать заявку',
            action: this.emit('revoke'),
            order: -1,
            when: (request, user) => {
                return request.authorId === user.id && request.status !== RequestStatusType.CLOSED;
            },
        },
        complete: {
            name: 'Выполнить',
            action: this.emit('complete'),
            order: 1,
            when: (request, user) => {
                return (
                    this.$_userRoleExists(user, AccountRoleType.EXECUTOR) &&
                    [RequestStatusType.WORKUP, RequestStatusType.REWORK].includes(request.status)
                );
            },
        },
        reject: {
            name: 'Отказать',
            action: this.emit('reject'),
            order: -1,
            when: (request, user) => {
                return (
                    this.$_userRoleExists(user, AccountRoleType.EXECUTOR) &&
                    [RequestStatusType.WORKUP, RequestStatusType.REWORK].includes(request.status)
                );
            },
        },
        pause: {
            name: 'Приостановить',
            action: this.emit('pause'),
            order: -1,
            when: (request, user) => {
                return (
                    this.$_userRoleExists(user, AccountRoleType.EXECUTOR) && request.status === RequestStatusType.WORKUP
                );
            },
        },
        resume: {
            name: 'Возобновить',
            action: this.emit('resume'),
            order: -1,
            when: (request, user) => {
                return (
                    this.$_userRoleExists(user, AccountRoleType.EXECUTOR) && request.status === RequestStatusType.PAUSED
                );
            },
        },
        revision: {
            name: 'На доработку',
            action: this.emit('revision'),
            order: -1,
            when: (request, user) => {
                return (
                    this.$_userRoleExists(user, AccountRoleType.EXECUTOR) && request.status === RequestStatusType.WORKUP
                );
            },
        },
    };

    emit(eventName) {
        return () => this.$emit(eventName, this.request);
    }

    get additionalButtons() {
        return Object.values(this.actions)
            .sort((_, right) => right.order)
            .filter((action) => action.when(this.request, this.$_account) && action !== this.activeButton);
    }

    get activeButton() {
        return Object.values(this.actions)
            .sort((_, right) => right.order)
            .find((action) => action.when(this.request, this.$_account));
    }

    canReturnToWork(){
        const formattedDate = this.request.closedAt.replace(' ', 'T');
        return DateTime.fromISO(formattedDate, { zone: 'UTC' }).diffNow('months').months > -1;
    }
}

export default RequestViewHeader;
</script>
<style lang="scss">
:root {
    --request-header-title-color: #212121;
}

.request-header {
    display: flex;
    width: 100%;
    justify-content: space-between;

    &__title {
        margin: 0;
        color: var(--request-header-title-color);
        font-size: 1.25rem;
        font-weight: 500;
        line-height: 40px;
        letter-spacing: 0.01rem;
    }

    &__action {
        margin-left: 8px;

        &:first-child {
            margin-left: 0;
        }
    }

    &__actions {
        display: flex;
        align-items: center;
    }
}
</style>
