<template>
    <div class="user-card">
        <div class="user-card__avatar">
            <div class="user-card__thumbnail">
                <img
                    class="user-card__thumbnail-photo"
                    :src="account.photo"
                    @load="onPictureLoaded"
                    v-show="!isPictureLoadFailed"
                    v-if="account.photo"
                />

                <AccountIcon
                    class="user-card__thumbnail-icon"
                    :size="24"
                    v-if="!account.photo || isPictureLoadFailed"
                ></AccountIcon>
            </div>
        </div>

        <template v-if="!pictureOnly">
            <div class="user-card__label">
                <span
                    class="user-card__label-name"
                    :class="{
                        'user-card__label-name_wide': !status,
                    }"
                >
                    {{ displayName }}
                </span>

                <span class="user-card__label-status" v-if="status">
                    {{ status }}
                </span>
            </div>
        </template>
    </div>
</template>

<script>
import AccountIcon from 'vue-material-design-icons/Account';

export default {
    components: {
        AccountIcon,
    },

    props: {
        status: {
            type: String,
            default: '',
        },

        account: {
            type: Object,
            default: () => ({}),
        },

        shortName: {
            type: Boolean,
            default: false,
        },

        pictureOnly: {
            type: Boolean,
            default: false,
        },
    },

    data: () => ({
        isPictureLoadFailed: true,
    }),

    methods: {
        onPictureLoaded() {
            this.isPictureLoadFailed = false;
        },
        toShortName() {
            const name = this.account.displayName;
            const segments = name.split(' ');

            if (segments.length !== 3) {
                return name;
            }

            const givenName = segments[1];
            const familyName = segments[0];

            return `${givenName} ${familyName}`;
        },
    },

    computed: {
        displayName() {
            if (this.shortName) {
                return this.toShortName(this.account.displayName);
            }

            return this.account.displayName;
        },
    },

    watch: {
        account(newValue, oldValue) {
            if (newValue?.id !== oldValue?.id) {
                this.isPictureLoadFailed = true;
            }
        },
    },
};
</script>

<style lang="scss">
:root {
    --user-card-icon: #484848;
    --user-card-label-name-color: #484848;
    --user-card-label-status-color: #aaaaaa;
}

.user-card {
    display: flex;
    width: 100%;
    height: 32px;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &__avatar {
        margin: 0 8px 0 0;
    }

    &__thumbnail {
        width: 32px;
        height: 32px;

        &-icon,
        &-photo {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background: var(--color-white);

            & svg {
                fill: var(--user-card-icon);
            }
        }

        &-photo {
            object-fit: cover;
        }

        &-icon {
            align-items: center;
            justify-content: center;
        }
    }

    &__label {
        display: flex;
        height: 100%;
        justify-self: center;
        flex-direction: column;
    }

    &__label-status {
        font-size: 0.75rem;
        line-height: 0.875rem;
        color: var(--user-card-label-status-color);
    }

    &__label-name {
        color: var(--user-card-label-name-color);
        font-size: 0.875rem;
        line-height: 1.125rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &_wide {
            line-height: 2rem;
        }
    }
}
</style>
