<template>
    <VGridPageColumn class="grid-page-column__context_no-bottom-padding" sm-6 xs-12>
        <div class="request-page-loader" v-if="isLoading">
            <VSpinner></VSpinner>
        </div>

        <div class="request-page-error" v-if="errorMessage">
            <span>{{ errorMessage }}</span>
        </div>

        <template v-else-if="request">
            <div class="request-page" :class="{ 'request-page_loading': isLoading }">
                <div class="request-page__row request-page__row_header">
                    <RequestViewHeader
                        :request="request"
                        @pause="onPause"
                        @resume="onResume"
                        @reject="onReject"
                        @revoke="onRevoke"
                        @accept="onAccept"
                        @complete="onComplete"
                        @revision="onRevision"
                        @actualize="onActualize"
                    ></RequestViewHeader>
                </div>

                <div class="request-page__row request-page__row_card" v-if="showRequestInfo">
                    <RequestViewCard
                        :request="request"
                        @changeService="changeService"
                        @changeCategory="changeService"
                        @changeExecutor="changeExecutor"
                    ></RequestViewCard>
                </div>

                <div class="request-page__row request-page__tabs">
                    <Tabs>
                        <Tab id="chat" name="Комментарии" :suffix="unreadCommentCount">
                            <RequestViewTabChat
                                :request="request"
                                @addComment="addComment"
                                @sendToBitrix24="sendToBitrix24"
                                @readComment="readComment"
                                @actualize = "onActualize"
                            ></RequestViewTabChat>
                        </Tab>
                        <Tab id="history" name="История">
                            <RequestViewTabHistory
                                :request="request"
                                :history="request.history"
                            ></RequestViewTabHistory>
                        </Tab>

                        <Tab id="observers" name="Наблюдатели">
                            <RequestViewTabObservers
                                :request="request"
                                :observers="request.observers"
                                @addObserver="addObserver"
                                @deleteObserver="deleteObserver"
                            ></RequestViewTabObservers>
                        </Tab>
                    </Tabs>

                    <VToggleButtonLink class="request-page__roll-button" v-model="showRequestInfo"
                        >{{ showRequestInfo ? 'Свернуть' : 'Развернуть' }}
                    </VToggleButtonLink>
                </div>
            </div>
        </template>
    </VGridPageColumn>
</template>

<script>
// Components
import { Tabs, Tab } from 'vue-tabs-component';
import { VGridPageColumn } from '../../components';
import VToggleButtonLink from '@/views/components/VButton/ToggleButtonLink.vue';

import RequestViewCard from './components/RequestViewCard.vue';
import RequestViewHeader from './components/RequestViewHeader.vue';
import RequestViewTabChat from './components/RequestViewTabChat.vue';
import RequestViewTabHistory from './components/RequestViewTabHistory.vue';
import RequestViewTabObservers from './components/RequestViewTabObservers.vue';
import VSpinner from '@/views/components/VProgress/VSpinner.vue';

// Other
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import ModalNames from '@/js/enums/ModalNames';
import RequestStatusType from '@/js/enums/RequestStatusType';
import { changeRequestStatus, getLastReadComment } from '@/js/api';
import last from 'lodash/last';
import {
    addRequestComment,
    addRequestObserver,
    changeRequestExecutor,
    changeRequestService,
    createTaskInBitrix24FromRequest,
    deleteRequestObserver,
} from '../../../js/api';
import { Watch } from 'vue-property-decorator';

const Workspace = namespace('workspace');

@Component({
    components: {
        VSpinner,
        VGridPageColumn,
        VToggleButtonLink,

        RequestViewTabChat,
        RequestViewTabHistory,
        RequestViewTabObservers,

        Tab,
        Tabs,

        RequestViewCard,
        RequestViewHeader,
    },
})
class RequestView extends Vue {
    @Workspace.State activeRequests;

    @Workspace.Mutation UPDATE_REQUEST_IN_CONTEXT;
    @Workspace.Mutation ADD_REQUEST_OBSERVER;
    @Workspace.Mutation DELETE_REQUEST_OBSERVER;

    isLoading = false;
    errorMessage = '';
    showRequestInfo = true;
    lastCommentToSend = null;
    lastCommentTimer = null;

    get unreadCommentCount() {
        const arr =
            this.request.comments.filter((comment) => comment.id > last(this.request.lastSeenComments)?.commentId) || 0;
        if (arr.length > 0) {
            return '<span class="request-page__unread_comment">' + '+' + arr.length + '</span>';
        } else {
            return ' ';
        }
    }

    async addComment(event) {
        if (!this.request || this.isLoading) {
            return;
        }

        const files = event.files;
        const comment = event.comment;

        // If a comment is not exists and one of files aren't uploaded.
        if (!comment && files?.every((x) => x.id === -1)) {
            return;
        }

        this.isLoading = true;

        try {
            const response = await addRequestComment(this.request.id, {
                files,
                comment,
            });
            this.UPDATE_REQUEST_IN_CONTEXT(response.data);
        } catch (error) {
            this.errorMessage = this.$errorMessage(error);
        } finally {
            this.isLoading = false;
        }
    }

    async addObserver(observer) {
        this.isLoading = true;

        try {
            const response = await addRequestObserver(this.request.id, {
                observerId: observer.id,
            });

            this.ADD_REQUEST_OBSERVER({
                request: this.request,
                observer: response.data,
            });
        } catch (error) {
            this.errorMessage = this.$errorMessage(error);
        } finally {
            this.isLoading = false;
        }
    }

    async deleteObserver(observer) {
        this.isLoading = true;

        try {
            await deleteRequestObserver(this.request.id, observer.id);

            this.DELETE_REQUEST_OBSERVER({
                request: this.request,
                observerId: observer.id,
            });
        } catch (error) {
            this.errorMessage = this.$errorMessage(error);
        } finally {
            this.isLoading = false;
        }
    }

    async changeService() {
        // If current doesn't have executor role, we do not allow to change the service.
        if (!this.$_isExecutor) {
            return;
        }

        let changeServiceParams = null;

        try {
            changeServiceParams = await this.$showModal(ModalNames.REQUEST_CHANGE_SERVICE, {
                request: this.request,
            });
        } catch (error) {
            // the user canceled the changes of the service.
            return;
        }

        try {
            this.isLoading = true;

            const response = await changeRequestService(this.request.id, changeServiceParams);

            this.UPDATE_REQUEST_IN_CONTEXT(response.data);
        } catch (error) {
            this.errorMessage = this.$errorMessage(error);
        } finally {
            this.isLoading = false;
        }
    }

    async changeExecutor() {
        // If current doesn't have executor role, we do not allow to change the executor.
        if (!this.$_isExecutor) {
            return;
        }

        let changeExecutorParams = null;

        try {
            changeExecutorParams = await this.$showModal(ModalNames.REQUEST_CHANGE_EXECUTOR, {
                request: this.request,
            });
        } catch (error) {
            // The user canceled the changes of the executor.
            return;
        }

        try {
            this.isLoading = true;

            const response = await changeRequestExecutor(this.request.id, changeExecutorParams);

            this.UPDATE_REQUEST_IN_CONTEXT(response.data);
        } catch (error) {
            this.errorMessage = this.$errorMessage(error);
        } finally {
            this.isLoading = false;
        }
    }

    async sendToBitrix24() {
        let title = null;
        let groupId = null;

        try {
            var result = await this.$showModal(ModalNames.REQUEST_SEND_TO_BITRIX24);

            title = result.title;
            groupId = result.groupId;
        } catch (error) {
            // The user canceled the changes of the executor.
            return;
        }

        try {
            this.isLoading = true;

            const response = await createTaskInBitrix24FromRequest(groupId, {
                title,
                requestId: this.request.id,
            });

            this.UPDATE_REQUEST_IN_CONTEXT(response.data);
        } catch (error) {
            this.errorMessage = this.$errorMessage(error);
        } finally {
            this.isLoading = false;
        }
    }

    async onPause() {
        try {
            const params = await this.$showModal(ModalNames.REQUEST_PAUSE);

            this.setRequestStatus(this.request, RequestStatusType.PAUSED, params);
        } catch (error) {
            return;
        }
    }

    onResume() {
        this.setRequestStatus(this.request, RequestStatusType.WORKUP);
    }

    onReject() {
        this.setRequestStatus(this.request, RequestStatusType.DENIED);
    }

    onRevoke() {
        this.setRequestStatus(this.request, RequestStatusType.CLOSED);
    }

    onAccept() {
        this.setRequestStatus(this.request, RequestStatusType.CLOSED);
    }

    async onComplete() {
        try {
            const params = await this.$showModal(ModalNames.REQUEST_SOLUTION);
            this.setRequestStatus(this.request, RequestStatusType.FINISH, params);
        }catch (error) {
            return;
        }
    }

    onRevision() {
        this.setRequestStatus(this.request, RequestStatusType.REWORK);
    }

    async onActualize() {
        if (RequestStatusType.CLOSED === this.request.status){
            let backToWork = null;
            try {
                backToWork = await this.$showModal(ModalNames.REQUEST_BACK_TO_WORK, {
                    request: this.request,
                });
                this.setRequestStatus(this.request, backToWork.status);
            }
            catch (error) {
                // the user canceled the return.
                return;
            }
        }
        this.setRequestStatus(this.request, RequestStatusType.WORKUP);
    }

    async setRequestStatus(request, status, params = {}) {
        this.isLoading = true;

        try {
            var response = await changeRequestStatus(request.id, status, params);

            this.UPDATE_REQUEST_IN_CONTEXT(response.data);
        } catch (error) {
            console.error(error);

            this.errorMessage = 'Что-то пошло не так, попробуйте перезагрузить страницу';
        } finally {
            this.isLoading = false;
        }
    }

    cleanContext() {
        this.isLoading = false;
    }

    readComment(comment) {
        if ((this.request.lastSeenComments?.[0]?.commentId || 0) >= comment.id) {
            return;
        }
        if (!this.lastCommentToSend || this.lastCommentToSend.id < comment.id) {
            this.lastCommentToSend = comment;
            if (!this.lastCommentTimer) {
                this.lastCommentTimer = setTimeout(this.sendReadComment, 1000);
            }
        }
    }

    async sendReadComment() {
        const response = await getLastReadComment(this.request, this.lastCommentToSend);
        this.UPDATE_REQUEST_IN_CONTEXT(response.data);
        this.lastCommentToSend = null;
        this.lastCommentTimer = null;
    }

    checkNewComment() {
        const comment = last(this.request.comments) || [];
        if (comment && (this.request.lastSeenComments?.[0]?.id || 0) <= comment.id) {
            getLastReadComment(this.request, comment);
        }
    }

    @Watch('request')
    requestWatcher() {
        this.checkNewComment();
    }

    get request() {
        return this.activeRequests.find((request) => request.id === this.requestId);
    }

    get requestId() {
        return parseInt(this.$route.params.id, 10) ?? null;
    }
}

export default RequestView;
</script>
<style lang="scss">
.request-page {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;

    &_loading {
        opacity: 0.6;
        cursor: wait;
        user-select: none;
        pointer-events: none;
    }

    &__row {
        display: flex;

        &_card,
        &_header {
            margin: 0 24px;
        }

        &_card {
            flex-shrink: 1;
            margin-top: 12px;
            margin-bottom: 12px;
        }

        &_header {
            flex-shrink: 1;
            margin-bottom: 12px;
        }
    }

    &__tabs {
        position: relative;
        display: flex;
        flex-grow: 1;
        height: 100%;
        min-height: 0;
    }

    &__roll-button {
        position: absolute;
        top: 0;
        right: 24px;
        padding: 15px 12px;
    }

    &__unread_comment {
        align-items: center;
        margin-left: 3px;
        padding: 3px 4px 2px 4px;
        gap: 10px;
        width: 20px;
        height: 17px;
        background: #30c07c;
        color: whitesmoke;
        border-radius: 3px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        line-height: 12px;
    }
}

.request-page-loader {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    align-items: center;
    justify-content: center;
    z-index: 100000;
}

.request-page-error {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
}

.last-comments {
    flex-direction: column;
    justify-content: center;
}
</style>
