<template>
    <VueSelect :options="options" class="v-select-light" multiple placeholder="Автор" v-model="value"></VueSelect>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';

const Workspace = namespace('workspace');

@Component
class FilterAuthor extends Vue {
    @Workspace.State((state) =>
        state.accounts.map((account) => ({
            value: account.id,
            label: account.displayName,
        })),
    )
    options;

    value = [];

    getValue() {
        return this.value.map((value) => value.value);
    }

    reset() {
        this.value = [];
    }
}

export default FilterAuthor;
</script>
