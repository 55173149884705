<template>
    <VueSelect :options="options" class="v-select-light" multiple placeholder="Исполнитель" v-model="value"></VueSelect>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import AccountRoleType from '@/js/enums/AccountRoleType';

const Workspace = namespace('workspace');

@Component
class FilterExecutor extends Vue {
    @Workspace.State((state) =>
        state.accounts
            .filter((account) => account.roles.find((role) => role.role === AccountRoleType.EXECUTOR))
            .map((account) => ({
                value: account.id,
                label: account.displayName,
            })),
    )
    options;

    value = [];

    getValue() {
        return this.value.map((value) => value.value);
    }

    reset() {
        this.value = [];
    }
}

export default FilterExecutor;
</script>
